import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import background from '../images/background.png'
import logo from '../images/logo-main.png';
import celestial from '../images/Characters/Michael.png';
import About from './About';
import World from './World';
import Feature from './Feature';
import Tokenomic from './Tokenomic';
import Timeline from './Timeline';

const Main = () => {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: '200vh',
    position: 'relative',
    // paddingTop: '60px',
  }}>
    <div style={{
      bottom: '0px',
      zIndex: '-999',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: '50% 0',
      filter: 'blur(8px)',
    }}>

    </div>
    <Container>
      <Row className='align-items-center'>
        <Col md>
          <Container>
            <img src={logo} width='100%' alt='' />
            <p>
              Convert your XP tokens across Ethereum, Polygon and BSC here
              Best Japanese NFT Marketplace
              & Multiverse Game
            </p>
          </Container>
        </Col>
        <Col md>
          <img src={celestial} width='100%' alt='' />
        </Col>
      </Row>
    </Container>
    <About />
    <World />
    <Feature />
    <Tokenomic />
    <Timeline />
  </div>;
};

export default Main;
