import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import styled from 'styled-components';

import { Controller, Navigation, EffectFade } from "swiper";

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/thumbs/thumbs.min.css'
import './Swiper.css';

import highlight from '../images/highlight.png';
import logoBg from '../images/logo-bg.png';
import celestial from '../images/celestial-bg.png';
import mortal from '../images/mortal-bg.png';
import phantom from '../images/phantom-bg.png';
import Michael from '../images/Characters/Michael2.png';
import Solomon from '../images/Characters/Solomon2.png';
import Lucifer from '../images/Characters/Lucifer2.png';
import Valentine from '../images/Characters/Valentine2.png';
import Gawain from '../images/Characters/Gawain2.png';
import Gabiel from '../images/Characters/Gabiel2.png';
import Ramiel from '../images/Characters/Ramiel2.png';
import Baphomet from '../images/Characters/Baphomet2.png';
import Azazel from '../images/Characters/Azazel2.png';

import MichaelCard from '../images/Cards/UR_Michael.png';
import SolomonCard from '../images/Cards/UR_Solomon.png';
import LuciferCard from '../images/Cards/UR_Lucifer.png';
import ValentineCard from '../images/Cards/SR_Valentine.png';
import GawainCard from '../images/Cards/SR_Gawain.png';
import GabielCard from '../images/Cards/SR_Gabiel.png';
import RamielCard from '../images/Cards/SR_Ramiel.png';
import BaphometCard from '../images/Cards/SR_Baphomet.png';
import AzazelCard from '../images/Cards/SR_Azazel.png';
import CharInfo from './CharInfo';
import CharSideBar from './CharSideBar';

const MainContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const MainBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: ${props => `url('${props.background}')`};
  background-position: center;
  background-size: cover;
  transform-origin: center;
  filter: blur(4px);
`;

const MySwiper = styled(Swiper)`
  background-image: url('${highlight}');
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: 100vh;

  & .swiper-slide {
    transition: opacity 300ms ease-in;
    opacity: 0 !important;
  }
  
  & .swiper-slide-active {
    opacity: 1 !important;
  }
`;

const ThumbnailBg = styled.div`
  position: absolute;
  z-index: 8;
  bottom: 0;
  width: 100%;
  height: 20vh;
  background-color: rgba(255,255,255,0.4);
`
const Thumbnail = styled(Swiper)`
  position: absolute;
  z-index: 9;
  left: calc(50% - 40vh);
  bottom: 0;
  width: 80vh;
  height: calc(20vh + 10vh);
  box-sizing: border-box;
  padding: calc(10vh + 5px) 70px 10px 70px;
  display: flex;
  align-items: center;
  
  & .swiper-slide {
    height: calc(20vh - 10px);
    opacity: 0.8;
    transition: transform 300ms ease-in;
  }
  
  & .swiper-slide-active {
    transform: scale(1.45) translateY(-4vh);
    opacity: 1;
  }
`;

const Card = styled.img`
  cursor: pointer;
  display: block;
  margin: 2px auto 0;
  height: calc(20vh - 14px);
`

const CharBg = styled.img`
  position: absolute;
  top: 0;
  left: calc(50% - 63vh);
  width: auto;
  height: 100vh;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`

const LogoBg = styled.div`
  position: absolute;
  z-index: 6;
  top: calc(30vh - 210px);
  left: calc(50% - 400px - 10vw);
  width: 250px;
  height: 250px;
  background-image: url(${logoBg});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.25;
`

const characterInfo = {
  celestial: [
    {
      name: 'Michael',
      class: 'Fighter',
      bg: Michael,
      card: MichaelCard
    },
    {
      name: 'Gabiel',
      class: 'Sorceror',
      bg: Gabiel,
      card: GabielCard
    },
    {
      name: 'Ramiel',
      class: 'Ranger',
      bg: Ramiel,
      card: RamielCard
    }
  ],
  mortal: [
    {
      name: 'Solomon',
      class: 'Fighter',
      bg: Solomon,
      card: SolomonCard
    },
    {
      name: 'Valentine',
      class: 'Sorceror',
      bg: Valentine,
      card: ValentineCard
    },
    {
      name: 'Gawain',
      class: 'Ranger',
      bg: Gawain,
      card: GawainCard
    }
  ],
  phantom: [
    {
      name: 'Lucifer',
      class: 'Fighter',
      bg: Lucifer,
      card: LuciferCard
    },
    {
      name: 'Baphomet',
      class: 'Sorceror',
      bg: Baphomet,
      card: BaphometCard
    },
    {
      name: 'Azazel',
      class: 'Ranger',
      bg: Azazel,
      card: AzazelCard
    }
  ]
}

const mainBgImg = {
  celestial,
  mortal,
  phantom
}

function Charater(props) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlSwiper, setControlSwiper] = useState(null);

  useEffect(() => {
    controlSwiper?.slideTo(0);
  }, [props.world, controlSwiper])
  

  return (<MainContainer>
    <MainBg background={mainBgImg[props.world]} />
    <CharSideBar world={props.world}/>
    <MySwiper
      onSwiper={setThumbsSwiper}
      spaceBetween={10}
      effect='fade'
      modules={[Controller, EffectFade]}
    >
      {characterInfo[props.world].map(char => {
        return (
          <SwiperSlide key={char.name}>
            <CharBg src={char.bg} />
            <CharInfo name={char.name} class={char.class}/>
            <LogoBg />
          </SwiperSlide>
        )
      })}
    </MySwiper>
    <Thumbnail
      onSwiper={setControlSwiper}
      centeredSlides={true}
      slidesPerView={4}
      slideToClickedSlide={true}
      navigation={true}
      controller={{ control: thumbsSwiper }}
      modules={[Navigation, Controller]}
    >
    {characterInfo[props.world].map(char => {
      return (
        <SwiperSlide key={char.name}>
          <Card src={char.card} />
        </SwiperSlide>
      )
    })}
    </Thumbnail>
    <ThumbnailBg />
  </MainContainer>
  );
}

export default Charater;
