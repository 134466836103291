import React from 'react'
import { Col } from 'reactstrap'
import styled from 'styled-components'

const Box = styled.div`
  left: 10%;
  width: 100%;
  height: 200px;
  background-color: rgba(255,255,255,0.7);
  margin-bottom: 40px;
  padding: 20px;
`
const P = styled.p`
  margin-top: 15px;
  &::before {
    content: '◀';
    margin-right: 10px;
  }
`

function Detail(props) {
  return (
    <Col className='d-flex justify-content-center'>
      <Box>
        <h4>{props.header}</h4>
        <P>
          {props.content}
        </P>
      </Box>
    </Col>
  )
}

export default Detail