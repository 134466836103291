import React from 'react'
import { Row, Container } from 'reactstrap'
import styled from 'styled-components'
import Detail from './Detail'

const FeatureContainer = styled(Container)`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgba(0,0,0,0.7);
  padding-top: 20px;
  padding-bottom: 40px;
`
const Header = styled.h1`
  padding: 20px;
  color: white;
`

function Feature() {
  return (
    <FeatureContainer>
      <Header>Game Features</Header>
      <Container>
        <Row xs={2} md={5}>
          <Detail
            header='NFT Market'
            content='Enjoy collecting, playing and own your favourite characters and trade with the community.'
          />
          <Detail
            header='Skill'
            content='More than 100 skills you can create your comnos by your imagination.'
          />
          <Detail
            header='PVE & Story'
            content='Adventure through an epic story line and earn your reward.'
          />
          <Detail
            header='PVP'
            content='An equal battle with basic stat. Only your strategy matter.'
          />
          <Detail
            header='Upgrade'
            content='Develop your NFT to become stronger and look cooler.'
          />
          <Detail
            header='Leader board'
            content='Become top of the chart in all category to get a huge reward.'
          />
          <Detail
            header='Honor system'
            content='Share your winning reward to the defeated and claim your honor worth more.'
          />
          <Detail
            header='Guild'
            content='More people are better. Build your guild and unite member to conquer and rule the world.'
          />
          <Detail
            header='Land'
            content='Own and build your land, farming, mining and more for you to get your daily reward.'
          />
          <Detail
            header='Raid Boss'
            content='Beware of mighty boss who would destroy your world.'
          />
        </Row>
      </Container>
    </FeatureContainer>
  )
}

export default Feature