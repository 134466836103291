import React from 'react';
import styled from 'styled-components';

import celestial from '../images/celestial-bg.png';
import mortal from '../images/mortal-bg.png';
import phantom from '../images/phantom-bg.png';
import Michael from '../images/Characters/Michael.png';
import Solomon from '../images/Characters/Solomon.png';
import Lucifer from '../images/Characters/Lucifer.png';
import { Link } from 'react-router-dom';

const Section = styled.div`
  flex-direction: column;
`

const BG = styled.div`
  background-image: ${props => `url('${props.background}')`};
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
  transition: all 300ms;
`

const BgHover = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: ${props => `url('${props.background}')`};
  background-repeat: no-repeat;
  background-position: calc(50% + 360px) calc(50% + 50px);
  background-size: 600px 400px;
  opacity: 0;
  transition: all 0.2s 0s ease-in;
  border: #fff solid 6px;
  box-sizing: border-box;
  transition: opacity 300ms;
`

const Name = styled.h1`
  position: absolute;
  z-index: 3;
  top: calc(50% - 30px);
  left: calc(50% - 150px);
  width: 300px;
  height: 60px;
  text-align: center;
  color: white;
`

const Land = styled.div`
  cursor: pointer;
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
  transition: all 300ms;
  &:hover::before {
    background: rgba(0,0,0,0.1);
  }

  &::before {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
  }

  &:hover ${BG} {
    transform: scale(1.05);
  }

  &:hover ${BgHover} {
    opacity: 1;
  }

  &:hover ${Name} {
    text-decoration: underline;
  }
`

function World() {
  return (
    <Section>
      <Land>
        <Link to='/character/celestial'>
          <Name>Celestial</Name>
          <BG background={celestial} />
          <BgHover background={Michael} />
        </Link>
      </Land>
      <Land>
        <Link to='/character/mortal'>
          <Name>Mortal</Name>
          <BG background={mortal} />
          <BgHover background={Solomon} />
        </Link>
      </Land>
      <Land>
        <Link to='/character/phantom'>
          <Name>Phantom</Name>
          <BG background={phantom} />
          <BgHover background={Lucifer} />
        </Link>
      </Land>
    </Section>
  );
}

export default World;
