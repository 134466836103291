import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components';

import tokenomic from '../images/tokenomic.png';

const Pie = styled.div`
  background-image: url(${tokenomic});
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 500px;
`

function Tokenomic() {
  return (
    <Container>
        <Row>
            <Col className='d-flex justify-content-center'>
                <h1>Tokenomic</h1>
            </Col>
        </Row>
        <Row>
            <Col className='d-flex justify-content-center'>
                <Pie />
            </Col>
        </Row>
    </Container>
  )
}

export default Tokenomic