import React from 'react';
import styled from 'styled-components';

import fighter from '../images/Class/Fighter.png';
import border from '../images/border.png';

const Container = styled.div`
  position: absolute;
  z-index: 7;
  top: calc(30vh - 140px);
  left: calc(50% - 300px - 10vw);
  width: calc(300px + 10vw);
`

const Inner = styled.div`
  background-color: rgba(0,0,0,0.2);
  padding: 20px;
`

const Border = styled.div`
  width: calc(260px + 10vw);
  height: calc(20px + 3vh);
  left: 0px;
  background-image: url(${border});
  background-size: calc(260px + 10vw) calc(20px + 3vh);
  background-repeat: no-repeat;
  /* position: absolute;
  z-index: 7;
  top: -90px; */
`

const Name = styled.h1`
  font-size: calc(2.5rem + 2vh);
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px rgba(255,255,255,0.4);
  color: rgba(0,0,0,0.7);
`

const CharClass = styled.div`
  background: rgba(255,255,255,0.5) url(${fighter}) no-repeat;
  background-size: calc(20px + 2vh);
  background-position: 10px;
  height: calc(30px + 2vh);
  margin: 20px 0;
  padding-left: 70px;
  padding-top: 5px;
  font-size: calc(0.8rem + 2vh);
  font-weight: 900;
`

const Info = styled.p`
  padding: 25px 15px;
  background-color: rgba(0,0,0,0.6);
  color: white;
  text-align: justify;
  font-size: calc(0.3rem + 1vh);
`

function CharInfo(props) {
  return (
    <Container>
      <Name>{props.name}</Name>
      <Inner>
        <CharClass>{props.class}</CharClass>
        <Info>In the Knights of Favonius, Kaeya is the most trusted aide for the Acting Grand Master Jean. You can always count on him to solve any intractable problems.</Info>
        <Border />
      </Inner>
    </Container>
  )
}

export default CharInfo