import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Autoplay } from "swiper";

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css'

import celestial from '../images/Characters/Michael.png';
import bg from '../images/mock-bg.jpeg';
import styled from 'styled-components';

const Outer = styled.div`
  padding: 50px;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  color: white;
`

function About() {
  return (
    <Outer>

      <Container>
        <Row className='align-items-center'>
          <Col md>
            <Container>
              <h1>ABOUT</h1>
              <p>
                Convert your XP tokens across Ethereum, Polygon and BSC here
                Best Japanese NFT Marketplace
                & Multiverse Game
              </p>
            </Container>
          </Col>
          <Col md>
            <Row xs='auto' className='justify-content-center'>
              <Col style={{ width: '610px' }}>
                <Swiper
                  autoplay={{
                    delay: 1000
                  }}
                  spaceBetween={10}
                  pagination={true}
                  modules={[Pagination, Autoplay]}
                // className="mySwiper2"
                >
                  <SwiperSlide>
                    <img alt='celestial' src={celestial} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-2.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-3.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-4.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-5.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-6.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-7.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-8.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-9.jpg" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img alt='' src="https://swiperjs.com/demos/images/nature-10.jpg" />
                  </SwiperSlide>
                </Swiper>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Outer>
  );
}

export default About;
