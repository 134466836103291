import Main from './components/Main';
import Charater from './components/Charater';
import NavBar from './components/NavBar';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  return (
    <Router>
      <div>
        <NavBar />
      </div>
      <Routes>
        <Route path="/character/celestial" element={<Charater world='celestial'/>} />
        <Route path="/character/mortal" element={<Charater world='mortal' />} />
        <Route path="/character/phantom" element={<Charater world='phantom' />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  )
};

export default App;