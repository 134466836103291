import React, { useState }  from 'react';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SLink = styled(Link)`
  text-decoration: none;
`

const NavBar = () => {
  const [open, setOpen] = useState(false);
  return <div>
    <Navbar
      // color="light"
      expand="md"
      light
      fixed='top'
      style={{backgroundColor: 'rgb(255,255,255,0.5)'}}
      className='pb-0 pt-0 ps-2 pe-2'
      container='md'
    >
      <NavbarBrand href="/" className='p-0'>
        <img src={logo} height='66px' alt=''/>
      </NavbarBrand>
      <NavbarToggler onClick={() => setOpen(!open)} />
      <Collapse isOpen={open} navbar>
        <Nav
          className="me-auto"
          navbar
        >
          <NavItem>
            <SLink to="/">
              <NavLink>
                Home
              </NavLink>
            </SLink>
          </NavItem>
          <NavItem>
            <SLink to="/character/celestial">
              <NavLink>
                Character
              </NavLink>
            </SLink>
          </NavItem>
        </Nav>
        <NavbarText>
          Login
        </NavbarText>
      </Collapse>
    </Navbar>
  </div>;
};

export default NavBar;
